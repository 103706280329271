import { data } from "autoprefixer";
import { axiosApi } from "../helpers/api_helper";

export class PaymentMethodRepository {
  endpoint = "/store_payment_methods";

  async list() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async getPaymentMethod() {
    const response = await axiosApi.get(`${this.endpoint}/latest-update`);
    return response.data;
  }

  async register(data) {
    const response = await axiosApi.post(
      `${this.endpoint}/first-payment`,
      data
    );
    return response;
  }

  async update(data) {
    const response = await axiosApi.post(this.endpoint, data);

    return response;
  }

  async changePaymentMethod(data) {
    return await axiosApi.post(this.endpoint, data);
  }

  async firstPayment(data) {
    const response = await axiosApi.post(`${this.endpoint}/first-payment-onvo`, data);
    return response;
  }

  async secondPayment(data) {
    const response = await axiosApi.post(`${this.endpoint}/first-payment-payvalida`, data);
    return response;
  }
}

export default PaymentMethodRepository;
