import { useEffect } from "react";
import PaymentMethodRepository from "../../repositories/paymenMethod";
import { useHistory } from "react-router-dom";

export const useCheckPaymentMethod = () => {
    const history = useHistory()
    useEffect(() => {
        const check = async () => {
            const paymentMethod = await new PaymentMethodRepository().getPaymentMethod()
            if (!!!paymentMethod || !paymentMethod.paymentMethod) {
                history.push("/pricing")
            }
        }
        check()
  }, [history]);
}