import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  data: {},
};

export const billingLatestSlice = createSlice({
  name: "billing/latest",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loaded: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});
export const billingLatestActions = billingLatestSlice.actions;
export default billingLatestSlice.reducer;
