import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "../../assets/images/logo-white.svg";
import "../../assets/scss/custom/pages/_finish-up.scss";
import { useCheckPaymentMethod } from "../../app/hook/useCheckPaymentMethod";
import "./index.css";
import { useIsDesktop } from "../../helpers/useIsDesktop";
import { useScreenHeight } from "../../helpers/useScreenHeight";
import bg_image from "../../assets/images/layouts/finish-up.png";

const FinishUpPage = (props) => {
  useCheckPaymentMethod();
  const { history } = props;

  const isDesktop = useIsDesktop();
  const screenHeight = useScreenHeight();

  return (
    <>
      {isDesktop ? (
        <div className="finish-up-page" style={{ height: screenHeight }}>
          <Container fluid={true}>
            <Row>
              <Col md={12}>
                <div className="logo-div">
                  <img className="bee-logo" alt="logo" src={logo} />
                </div>
                <div className="main-heading">
                  <p>¡Hola de nuevo!</p>
                </div>
                <div className="main-head-details">
                  Termina de configurar <br />
                  tu plan en 2 simples pasos
                </div>
                <div className="continuar-button mt-5">
                  <Button
                    onClick={() => history.push("/profile-details")}
                    className="button"
                  >
                    CONTINUAR{" "}
                    <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          className="finish-up-page-responsive"
          style={{
            height: screenHeight,
            minHeight: screenHeight,
            maxHeight: screenHeight,
            overflow: "hidden"
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-center">
                <img
                  className="d-flex align-self-center bee-logo mt-5"
                  alt="logo"
                  src={logo}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className="w-100 text-center mt-2">
                <p className="responsive-text-header">¡Hola de nuevo!</p>
              </div>
            </Col>
            <Col xs={12}>
              <div className="w-100 text-center">
                <span className="responsive-text-title">
                  Termina de configurar <br />
                  tu plan en 2 simples pasos
                </span>
              </div>
            </Col>
            <Col xs={12} className="mt-5">
              <div className="d-flex justify-content-center">
                <Button
                  onClick={() => history.push("/profile-details")}
                  className="responsive-finish-button"
                >
                  CONTINUAR{" "}
                  <i className="fa-solid fa-arrow-right text-white ms-2"></i>
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-end">
                <img
                  className="bee-logo mt-5"
                  alt="background"
                  src={bg_image}
                  width={window.innerWidth * 2}
                  height={"auto"}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FinishUpPage;
