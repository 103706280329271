import { combineReducers } from "@reduxjs/toolkit";

import prizeRedeemed from "./prizeRedeemed/slice";
import schedule from "./schedule/slice";
import users from "./users/slice";
import members from "./members/slice";
import cardConfig from "./cardConfig/slice"
import pricing from "./subscriptionProduct/slice";

export default combineReducers({
  prizeRedeemed,
  schedule,
  usersByStore: users,
  members,
  pricing,
  cardConfig
});
