import { axiosApi } from "../helpers/api_helper";

export class StoreCategoriesRepository {
  endpoint = "/stores_categories";

  async listAll() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async listAllByCountry(country_id) {
    const response = await axiosApi.get(`${this.endpoint}/${country_id}`);
    return response.data;
  }
}

export default StoreCategoriesRepository;
